import './partnersBanner.scss';
import partnersImage from '../../assets/images/partners.jpg';
import mobileCondition from '../../constants/mobileCondition';

const PartnersBanner = () => {
  const titleClassName = `partners-banner-title ${mobileCondition ? 'partners-banner-title_mobile' : ''}`;

  const sliderClassName = `partners-banner-container ${mobileCondition ? 'partners-banner-container_mobile' : ''}`;

  return (
    <div className={sliderClassName}>
      <h2 className={titleClassName}>Some of Our Partners</h2>
      <div className="partners-banner">
        <img
          src={partnersImage}
          alt="Partners"
        />
      </div>
    </div>
  );
};

export default PartnersBanner;
